import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "nucleus-nodejs"
    }}>{`nucleus-nodejs `}<a parentName="h1" {...{
        "href": "https://www.npmjs.com/package/nucleus-nodejs"
      }}><img alt="npm" src="https://img.shields.io/npm/v/nucleus-nodejs.svg" /></a></h1>
    <p>{`Analytics, licensing and bug reports for Node.js, Electron and NW.js desktop applications.`}</p>
    <p>{`We made it as simple as possible to report the data you need to analyze your app and improve it.`}</p>
    <p>{`To start using this module, sign up and get a tracking ID on `}<a parentName="p" {...{
        "href": "https://nucleus.sh"
      }}>{`Nucleus.sh`}</a>{`. `}</p>
    <b>Electron:</b>
    <p>{`This module works in both the renderer and the main process.
However be sure to only call the `}<inlineCode parentName="p">{`appStarted()`}</inlineCode>{` method once per session (in only one process) or you'll find duplicate data in the dashboard.`}</p>
    <p>{`This module can even run in a browser outside of Node (for example in the Electron renderer process with Node Integration disabled).`}</p>
    {
      /*
      # 3.0.0 Breaking changes:
      The version 3 of the module introce breaking changes, so be careful to update your integration before upgrading.
      - You now have to manually call the `init` and `appStarted` methods to start the analaytics session. This was because the previous way was confusing between processes.
      - The module is now 100% independent from Node and can run in an isolated browser context
      - Options were renamed 
      */
    }
    <h2 {...{
      "id": "installation"
    }}>{`Installation`}</h2>
    <p>{`Using npm:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ npm install nucleus-nodejs --save
`}</code></pre>
    <h2 {...{
      "id": "usage"
    }}>{`Usage`}</h2>
    <p>{`Sign up and get a tracking ID for your app `}<a parentName="p" {...{
        "href": "https://nucleus.sh"
      }}>{`here`}</a>{`.`}</p>
    <p>{`Call the appStarted method `}<em parentName="p">{`only one time`}</em>{` per session.`}</p>
    <p>{`You only need to call `}<inlineCode parentName="p">{`init`}</inlineCode>{` once per process.`}</p>
    <p>{`If you use the module in both the main and renderer process, make sure that you only call `}<inlineCode parentName="p">{`appStarted`}</inlineCode>{` once.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const Nucleus = require("nucleus-nodejs")

Nucleus.init("<Your tracking ID>")

// Optional: sets an user ID
Nucleus.setUserId('richard_hendrix')

// Required: Sends the first event to the server that app started
Nucleus.appStarted()

// Report things
Nucleus.track("PLAYED_TRACK", {
    trackName: 'My Awesome Song',
    duration: 120
})
`}</code></pre>
    <h3 {...{
      "id": "options"
    }}>{`Options`}</h3>
    <p>{`You can init Nucleus with options:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const Nucleus = require("nucleus-nodejs")

Nucleus.init("<Your tracking Id>", {
    disableInDev: false, // disable module while in development (default: false)
    disableTracking: false, // completely disable tracking from the start (default: false)
    disableErrorReports: false, // disable errors reporting (default: false)
    autoUserId: false, // auto assign the user an id: username@hostname
    debug: true // Show logs
})

Nucleus.appStarted()
`}</code></pre>
    <p><strong parentName="p">{`Each property is optional`}</strong>{`. You can start using the module with just the tracking ID.`}</p>
    <p>{`The module will try to autodetect a maximum of data as possible but some can fail to detect (especially if in a Browser outside of Node).`}</p>
    <p>{`It will tell you in the logs (if you set `}<inlineCode parentName="p">{`debug: true`}</inlineCode>{`) which one failed to detect.`}</p>
    <p>{`You can also change the data, if you make sure to do it before the `}<inlineCode parentName="p">{`appStarted`}</inlineCode>{` method.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`Nucleus.setProps({
    version: '0.3.1',
    language: 'fr'
    // ...
})

Nucleus.appStarted()
`}</code></pre>
    <p><strong parentName="p">{`Note`}</strong>{` : when running in development, the app version will be '0.0.0'`}</p>
    <h3 {...{
      "id": "identify-your-users"
    }}>{`Identify your users`}</h3>
    <p>{`You can track specific users actions on the 'User Explorer' section of your dashboard.`}</p>
    <p>{`For that, you can supply an `}<inlineCode parentName="p">{`userId`}</inlineCode>{` when initing the Nucleus module. `}</p>
    <p>{`It can be your own generated ID, an email, username... etc.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`Nucleus.setProps({
    userId: 'someUniqueUserId'
})
`}</code></pre>
    <p>{`Or:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`Nucleus.setUserId('someUniqueUserId')
`}</code></pre>
    <p>{`Alternatively, set the `}<inlineCode parentName="p">{`autoUserId`}</inlineCode>{` option of the module to `}<inlineCode parentName="p">{`true`}</inlineCode>{` to assign the user an ID based on his username and hostname.`}</p>
    <h3 {...{
      "id": "add-properties"
    }}>{`Add properties`}</h3>
    <p>{`You can report custom data along with the automatic data.`}</p>
    <p>{`Those will be visible in your user dashboard if you previously set an user ID.`}</p>
    <p>{`The module will remember past properties so you can use `}<inlineCode parentName="p">{`Nucleus.setProps`}</inlineCode>{` multiple times without overwriting past props.`}</p>
    <p>{`Properties can either `}<strong parentName="p">{`numbers`}</strong>{`, `}<strong parentName="p">{`strings`}</strong>{` or `}<strong parentName="p">{`booleans`}</strong>{`.
Nested properties or arrays aren't supported at the moment.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`Nucleus.setProps({
    age: 34,
    name: 'Richard Hendricks',
    jobType: 'CEO'
})
`}</code></pre>
    <p>{`Enable overwrite: set the second parameter as true to overwrite past properties. `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`Nucleus.setProps({
    age: 23
}, true)
`}</code></pre>
    <h3 {...{
      "id": "events"
    }}>{`Events`}</h3>
    <p>{`After initializing Nucleus, you can send your own custom events.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`Nucleus.track("PLAYED_TRACK")
`}</code></pre>
    <p>{`They are a couple event names that are reserved by Nucleus: `}<inlineCode parentName="p">{`init`}</inlineCode>{`, `}<inlineCode parentName="p">{`error:`}</inlineCode>{` and `}<inlineCode parentName="p">{`nucleus:`}</inlineCode>{`. Don't report events containing these strings.`}</p>
    <h4 {...{
      "id": "attach-more-data"
    }}>{`Attach more data`}</h4>
    <p>{`You can also add extra information to tracked events, as a JSON object.`}</p>
    <p>{`Properties can either `}<strong parentName="p">{`numbers`}</strong>{`, `}<strong parentName="p">{`strings`}</strong>{` or `}<strong parentName="p">{`booleans`}</strong>{`.
Nested properties or arrays aren't supported at the moment.`}</p>
    <p>{`Example`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`Nucleus.track("PLAYED_TRACK", {
    trackName: 'My Awesome Song',
    duration: 120
})
`}</code></pre>
    <h3 {...{
      "id": "toggle-tracking"
    }}>{`Toggle tracking`}</h3>
    <p>{`This will completely disable any communication with Nucleus' servers.`}</p>
    <p>{`To opt-out your users from tracking, use the following methods:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`Nucleus.disableTracking()
`}</code></pre>
    <p>{`and to opt back in:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`Nucleus.enableTracking()
`}</code></pre>
    <p>{`This change won't persist after restarts so you have to handle the saving of the settings.`}</p>
    <p>{`You can also supply a `}<inlineCode parentName="p">{`disableTracking: true`}</inlineCode>{` option to the module on start if you want to directly prevent tracking.`}</p>
    <h3 {...{
      "id": "errors"
    }}>{`Errors`}</h3>
    <p>{`Nucleus will by default report all `}<inlineCode parentName="p">{`uncaughtException`}</inlineCode>{` and `}<inlineCode parentName="p">{`unhandledRejection`}</inlineCode>{`.`}</p>
    <p>{`If you'd like to act on these errors, for example show them to your user, quit the app or reload it, you can define an onError function, which will be called on errors happening on the respective process.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`Nucleus.onError = (type, err) => {
    console.error(err)
    // type will either be uncaughtException, unhandledRejection or windowError
}
`}</code></pre>
    <p><inlineCode parentName="p">{`windowError`}</inlineCode>{` is an `}<inlineCode parentName="p">{`uncaughtException`}</inlineCode>{` that happened in the renderer process. It was catched with `}<inlineCode parentName="p">{`window.onerror`}</inlineCode>{`.`}</p>
    <p>{`If you'd like to report another type of error, you can do so with:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`Nucleus.trackError('myCustomError', err)
`}</code></pre>
    <h3 {...{
      "id": "updates"
    }}>{`Updates`}</h3>
    <p>{`If the user is running a version inferior to the one set in your app settings (account section in the dashboard), it can call a function so you can alert the user (or something else).`}</p>
    <p>{`If there's an update, the function will be called when starting the app.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`Nucleus.onUpdate = (lastVersion) => {
    alert('New version available: ' + lastVersion)
}
`}</code></pre>
    <p><strong parentName="p">{`Note`}</strong>{` : when running in development, the app version will be '0.0.0', so you can test this by setting a higher version in your dashboard`}</p>
    <hr></hr>
    <p>{`Contact `}<strong parentName="p"><a parentName="strong" {...{
          "href": "mailto:hello@nucleus.sh"
        }}>{`hello@nucleus.sh`}</a></strong>{` for any inquiry`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      